<!--
  1.状态
  未开始：未到活动时间，支持查看  修改  删除
  进行中：活动进行中，支持查看  修改  结束
  已过期：活动时间已结束，支持查看，释放商品
  已结束：手动结束该活动，支持查看，释放商品

  1.同一商品仅支持绑定同一个商品，在状态为已过期或已结束时释放商品
  2.支持修改内容：支持修改活动名称、活动时间、活动商品增加及删除，赠品增加及删除。
  3.一个商品支持赠送多个赠品，一个赠品支持多个数量
  4.赠品活动失效或赠品库存为0时，活动商品不展示赠品信息。
 -->
<template>
  <moe-page title="买赠活动">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">买赠活动</div>
          </div>
        </div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="buyGiftSearch">
        <el-form-item label="状态">
          <moe-select type="buyGiftStateList" v-model="buyGiftParams.listState" placeholder="选择状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="buyGiftParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动编号">
          <el-input :value="buyGiftParams.id" @input="(value) => buyGiftParams.id = $moe_formatter.formatterId(value)" placeholder="输入活动编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="使用时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([buyGiftParams.startTime, buyGiftParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="buyGiftTableRef" url="/shop/buyGift/list" :params="buyGiftParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/buyGift/add')">新建赠品</el-button>

        <el-table-column label="活动信息" min-width="150">
          <template slot-scope="{ row }">
            <div>{{ row.name }}</div>
            <div>活动编号:{{ row.id }}</div>
          </template>
        </el-table-column>

        <el-table-column label="使用时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>

        <el-table-column label="状态" min-width="150">
          <div :class="$moe_format.getBuyGiftStateListColor(row.listState)" slot-scope="{ row }">
            {{ $moe_format.getBuyGiftStateList(row.listState) }}
          </div>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="450">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/buyGift/detail`, { id: row.id })">查看活动</el-button>
            <el-button v-if="['NOT_STARTED', 'IN_PROGRESS'].includes(row.listState)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/buyGift/add`, { id: row.id })">编辑活动</el-button>
            <el-button v-if="['IN_PROGRESS'].includes(row.listState)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleEnd(row)">结束</el-button>
            <el-button v-if="['NOT_STARTED'].includes(row.listState)" type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageBuyGiftList',
  data() {
    return {
      buyGiftParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        listState: '',
        name: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
    }
  },
  methods: {
    buyGiftSearch(isSearch) {
      if (!isSearch) {
        this.buyGiftParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          listState: '',
          name: '',
          startTime: '',
          endTime: '',
        }

        this.datetime = [];
      }

      this.$refs['buyGiftTableRef'].searchData();
    },
    handleEnd({ name, id }) {
      this.$moe_layer.confirm(`您确定要结束当前选项"${name}"吗？`, () => {
        this.$moe_api.BUYGIFT_API.closeBuyGift({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('结束成功');
            this.buyGiftSearch(false);
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
    handleDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.BUYGIFT_API.delBuyGift({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.buyGiftSearch(false);
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    }
  }
}
</script>

<style>

</style>